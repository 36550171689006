@font-face {
  font-family: 'groovy';
  src: url('./styles/fonts/groovy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gecko';
  src: url('./styles/fonts/gecko.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

$primary-color: #81D3CC;
$secondary-color: #E69418;
$background-color: #f3f3f3;
$text-color: #333;
$dark-background: #161635;
$iphone-grey: #E6E5EB;
$iphone-blue: #0A80FF;
$dark-bg: #415760;

body {
  margin: 0;
  font-family: 'groovy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: $dark-bg;
}

a {
  text-decoration: none;
}


.topnav {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  padding: 10px 20px;
  z-index: 1000;
  
  h1 {
    color: white;
    margin: 0;
    flex-grow: 0;
  }


  
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 20px;
    cursor: pointer;
    
    .bar {
      width: 25px;
      height: 3px;
      background-color: white;
      border-radius: 2px;
    }
  }

  .dropdown {
    // position: absolute;
    // top: 60px; 
    // right: 20px;
    // background-color: #fff;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0;

    .close-icon {
      position: absolute;
      top: 10px;  // adjust as needed
      right: 10px;  // adjust as needed
      cursor: pointer;
      font-size: 24px;  // adjust as needed
      color: white;
      background-color: rgba(0, 0, 0, 0.5);  // semi-transparent background for better visibility
      border-radius: 50%;  // makes it circular
      padding: 5px;  // adjust as needed
      line-height: 1;  // centers the "X" vertically
  
      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }

    // @media (max-width: 768px) {  // Adjust breakpoint as per your requirements
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9); // Semi-transparent black background
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      a, .sign-up {
        margin: 10px 0; // Add some spacing between links
      }
    // }

    .menu-content {
      // Center the links in the middle of the modal
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
      a, .sign-up {
        margin: 20px 0; // Add some spacing between links
        color: white;
        font-size: 2rem;
      }
    }

    a {
      display: block;
      color: black;
      text-decoration: none;
      padding: 10px 15px;

      &:hover {
        color: $secondary-color;
      }

      &.active {
        color: $primary-color;
      }
    }

    .sign-up {
      display: block;
      background-color: $secondary-color;
      color: white;
      border: none;
      border-radius: 40px;
      padding: 20px 40px;
      text-align: center;
      margin: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
      font-family: 'groovy';

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    

    
  }
}

.title-splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100vh; // Making it a full screen height splash, adjust as needed
  height: 90vh;
  text-align: center;
  padding: 30px; // Providing some padding to avoid edge text
  background-image: url("./assets/disco.jpeg");
  background-position: bottom left;
  background-size: cover;
  color: white;

  h1 {
    font-size: 2.5rem; // Adjust according to your design preference
    margin-bottom: 30px; // Some margin to separate the title from the rest
  }

  h2 {
    font-family: 'gecko', sans-serif;
    font-size: 1.5rem; // Medium font-size
    margin-bottom: 30px;
    max-width: 800px; // To ensure the text doesn't stretch too wide on larger screens
  }

  h3 {
    font-size: 1rem; // Smallest font size
    font-family: 'gecko', sans-serif;
    margin-bottom: 30px;
    color: $secondary-color;

  }

  .highlight {
    color: $primary-color;
  }

  .highlight2 {
    color: $secondary-color;
  }

  .sign-up {
    display: block;
    background-color: $secondary-color;
    color: white;
    border: none;
    border-radius: 40px;
    padding: 20px 40px;
    text-align: center;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'groovy';
    font-size: 1.5rem;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  .learn {
    display: block;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 40px;
    padding: 10px 20px;
    text-align: center;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'groovy';
    font-size: 1rem;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}

.info-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: url("./assets/disco.jpeg");
  background-position: bottom left;
  background-size: cover;
  height: 100vh;

  h1 {
    font-size: 3rem;
    margin-bottom: 30px;
    color: $secondary-color
  }

  .info-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;

    .info-box {
      &:nth-child(odd) {
        margin-right: auto;
        background-color: $iphone-grey;
      }

      &:nth-child(even) {
        margin-left: auto;
        background-color: $iphone-blue;
        color: white;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .sign-up {
    display: block;
    background-color: $secondary-color;
    color: white;
    border: none;
    border-radius: 40px;
    padding: 20px 40px;
    text-align: center;
    margin: 30px 10px 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'groovy';
    font-size: 1.5rem;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}

.info-box {
  width: 80%; // or adjust based on your design preference
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 1.1rem;
  font-family: 'gecko'
}

.boogie-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: $dark-bg;
  color: white;

  h1 {
    font-size: 3rem;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 20px;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 80%; // Adjust as per your design preference
    font-family: 'gecko';

    input[type="text"], textarea {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 1rem;

      &:focus {
        border-color: $primary-color;
        outline: none;
      }
    }

    label {
      display: flex;
      align-items: center;
      margin: 5px 0;

      input[type="checkbox"] {
        margin-right: 10px;
      }
    }

    .disclaimer {
      font-size: 12px;  // Setting a smaller font size for the disclaimer
      color: #888;     // A lighter color to differentiate from the main content
      margin-top: 10px; // Space it out from the rest of the content
      line-height: 1.5; // Improved readability
      
      a {
        color: #0077cc;  // Standard link color. Adjust as needed.
        text-decoration: underline; // Underline for clarity
        
        &:hover {
          color: #0055a5; // Slightly darker color on hover
        }
      }
    }

    .submit-section {
      margin-top: 20px;  // Some spacing from the elements above
      text-align: center;  // Center align the elements within
  
      .user-count {
          font-weight: bold;  // Making it bold as requested
          margin-bottom: 10px;  // Some spacing between the text and the button
      }
  
      .sign-up {
        display: block;
        background-color: $secondary-color;
        color: white;
        border: none;
        border-radius: 40px;
        padding: 20px 40px;
        text-align: center;
        margin: 30px auto;
        cursor: pointer;
        transition: background-color 0.3s;
        font-family: 'groovy';
        font-size: 1.5rem;
        

    
        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }
  }
  }
}

.tos-container {
  padding: 40px;
  background-color: $dark-bg;
  color: white;

  h1 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
  }

  .tos-content {
    font-family: 'gecko';
    h2 {
      font-size: 24px;
      margin-top: 20px;
    }

    p {
      font-size: 16px;
      margin-top: 10px;
      line-height: 1.5;
    }
  }
}

.privacy-container {
  padding: 40px;
  background-color: $dark-bg;
  color: white;


  h1 {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
  }

  .privacy-content {
    font-family: 'gecko'
    h2 {
      font-size: 24px;
      margin-top: 20px;
    }

    p {
      font-size: 16px;
      margin-top: 10px;
      line-height: 1.5;
    }
  }
}

.contact-container {
  padding: 40px;
  text-align: center;  // To center align the content
  background-color: $dark-bg;
  color: white;

  h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 24px;
    margin-top: 20px;

    a {
      color: inherit;  // To keep the link the same color as the text
      text-decoration: underline;  // To indicate it's clickable

      &:hover {
        color: $primary-color;  // Assuming you have this variable from previous code
      }
    }
  }
}